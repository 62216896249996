.container img {
  width: 90px;
  height: 33px;
  justify-content: center;
  display: block;
  margin: 30px auto 10px auto;
  background: none;
}
.container div {
  align-items: center;
  justify-items: center;
  text-align: center;
}

.container {
  font-size: 15px;
}

.container p {
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.container p span {
  background: none;
}



@media (max-width: 420px) {
  .container {
    font-size: 14px;
  }
}

@media (max-width: 340px) {
  .container {
    font-size: 12px;
  }
}
